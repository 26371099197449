h1, h2, h3 {
  font-family: 'Neuton', sans-serif;
  font-weight: 700;
  font-size:2.75em;
  text-transform:uppercase;
}

h4, h5, h6, div, button {
  font-family: 'Neuton', sans-serif;
}

h4 {
  font-size:1.5em;
  font-weight:700;
}

h5 {
  font-size:1.4em;
}

p, li, .form-label, input, .form-check-label, textarea, select {
  font-family: 'Manrope', sans-serif;
  font-size: 18px!important;
}

body {
  background-color:#efefef;
}

.nav-link {
  font-family:'Manrope', sans-serif;
}

#vidback {
  position:fixed;
  right:0;
  bottom:0;
  min-width:100%;
  min-height:100%;
}

.addheight {
  height:500px;
}

.row {
  margin:0;
}

.banner {
  width:100%;
  min-width:100%;
  height:600px;
  min-height:600px;
  background-image:url('./images/setroad.jpg');
  background-size:cover;
  background-position:center;
}

.welcomebanner {
  width:100%;
  min-width:100%;
  height:750px;
  min-height:750px;
  margin-bottom:-45px;
  background-image:url('./images/welcomemeet.jpg');
  background-size:cover;
  background-position:center;
}

.orderbanner {
  width:100%;
  min-width:100%;
  height:500px;
  min-height:500px;
  background-image:url('./images/office.jpg');
  background-size:cover;
  background-position:center;
}

.bantext {
  padding-top:200px;
  color:#efefef;
  text-shadow:#333333 1px 1px;
  text-align:center;
}

.headline {
  text-align:center;
}

.headline h1 {
  font-size:72px;
  text-shadow:#030303 1px 1px 3px;
  margin-top:70px;
  font-weight:bold!important;
  color:#efefef!important;
  text-transform:uppercase;
}

.headline h4 {
  font-weight:bold;
  font-size:36px;
  text-shadow:#030303 1px 1px 3px;
  color:#efefef;
}

.sidenote {
  color:#ffffff;
}

.cta {
  text-align:center;
}

.custom_button {
   text-align: center;
   display: block;
   cursor: pointer;
   width: 200px;
   max-height: 50px;
   padding: 8px 16px;
   background-color: #00528a;
   color: rgb(255, 255, 255);
   font-size: 20px;
   font-weight: bold;
   text-decoration: none;
   border-radius: 5px;
   --darkreader-inline-border-top:#373b3e;
   --darkreader-inline-border-right:#373b3e;
   --darkreader-inline-border-bottom:#373b3e;
   --darkreader-inline-border-left:#373b3e;
   --darkreader-inline-bgcolor:#2e7396;
   --darkreader-inline-color:#e8e6e3;
   visibility: visible;
}

.callbtn .col-6 {
  text-align:center;
}

.highlightid {
  color:red !important;
  font-weight:bold;
}

.vidbox {
  margin:25px auto;
  border:#000000 2px solid;
}

.vidplay .col-8 h3 {
  color:#e9e9e9;
  text-shadow:#b28c0d 1px 1px;
  font-weight:bold;
}

.vidcall {
  background-color:#efefef;
  border-top:#b28c0d 5px solid;
  height:300px;
}

.vidcall h3 {
  color:#ffffff;
  text-shadow:#000000 1px 1px;
  margin-top:50px;
}

.vidcall p {
  color:#ffffff;
}

.formcall {
  background-color:#ffffff;
  margin:50px 0;
}

.formcall h3 {
  color:#000000;
  text-shadow:#b28c0d 1px 1px;
}

.bottxt {
  text-align:center;
}

.bottxt h3 {
  text-shadow:#b28c0d 1px 1px;
  margin-top:80px;
}

.bottxt p {
  font-size:22px;
}

.video {
  text-align:center;
  width:100%;
  background-color:#efefef;
  height:300px;
  background-position:center;
  background-size:cover;
}

.thanktext {
  margin-top:350px;
  text-shadow:#000000 1px 1px 3px;
  font-size:48px;
}

.opt {
  margin-top:50px;
  margin-bottom:50px;
}

.opt form {
  width:100%;
  text-align:center;
}

.optform {
  text-align:center;
}

.optform h2 {
  text-shadow:#003a9a 1px 1px;
}

.optform .form-control {
  width:100%;
}

.gather h1 {
  font-weight:bold;
  text-shadow:#00528a 1px 1px;
}

.gather h3 {
  font-weight:bold;
  text-shadow:#b28c0d 1px 1px;
  font-size:32px;
  margin-bottom:50px;
  color:#efefef;
}

#formstyler {
  background: rgb(10,17,77);
  background: radial-gradient(circle, rgba(10,17,77,1) 0%, #00528a 100%);
  padding:15px;
  border:#b28c0d 2px solid;
  border-radius:10px;
  box-shadow:#000000 2px 2px;
}

#formstyler h2, #formstyler h3 {
  color:#eeeeee;
  font-size:28px;
  margin:0 0 10px 0;
}

.col-12 {
  text-align:center;
}

.col-12 img {
  max-width:75%;
}

.vidplay {
  background-color:#efefef;
}

.vidplay .col-8 {
  text-align:center;
}

.vidplay iframe {
  margin:50px 0;
  border:#0a114d 1px solid;
}

.navbar-brand img {
  max-height:100px;
}

.navi {
  background-color:#efefef;
}

.nav-link {
  color:#333333 !important;
}

.navbar-nav a:hover {
  color: #b28c0d !important;
}

/* Gather Page */
.gather img {
  margin:50px 0;
}

.gather h1 {
  font-size:36px;
  margin-top:50px;
  color:#efefef;
}

#gatherform .form-control {
  margin:10px 0;
}

#gatherform .form-label {
  color:#eeeeee;
  font-size:24px;
  font-weight:bold;
  font-family: 'Nunito Sans', sans-serif;
}

.ytimg img {
  border:#efefef 2px solid;
}

.ytimg img:hover {
  border:#b28c0d 2px solid;
}

.platforms div {
  text-align:center;
}

.platforms img {
  max-width:100%;
  width:75%;
}

/*creds page*/
.timeline {
  margin:50px auto;
}

.timeline p {
  padding:20px;
}

.timeline .card-header {
  cursor: pointer;
}

#yt iframe {
  margin: 50px 0;
}

#anal img {
  margin-bottom: 50px;
  border:#000000 1px solid;
}

#anal h1 {
  font-size:36px;
  margin-bottom:50px;
  color:red;
}

.carousel-container{
  width:100%;
  height:fit-content;
}

.tab-content {
  background-image:url('./images/subbkg2.webp');
  background-repeat:repeat;
}

#entTabs {
  border:#000000 1px solid!important;
}

.entities .row {
  max-height:fit-content;
  margin-bottom:25px;
}

.entimg {
  max-height:300px;
}

.entities .card {
  height:100%;
  background-color:#00528a!important;
}

.entities .card-header {
  background-color:#004179;
}

.acchead p {
  font-size:16px;
  font-weight:normal;
}

.accblue {
  background-color:rgba(13,52,178,0.5);
}

.accltb {
  background-color:rgba(13,52,178,0.5);
}

.accordion {
  margin-bottom:50px;
}

.accordion-body .container {
  border:#aeaeae 1px solid;
}

.accban {
  margin-bottom:50px;
}

.statecards .card-group {
  margin-top:25px;
}

.statecards .card-img-top {
  max-width:100%;
  max-height:225px;
}

.statecards .card-title {
  text-shadow:none;
}

.statecards .card-text {
  text-shadow:none;
}

.card {
  text-align:center;
}

.card-img-top {
  max-width:200px;
  max-height:50%;
  margin:auto;
}

.card-title {
  font-size:32px;
  font-weight:bold;
  text-shadow:#000000 1px 1px;
}

.card-text {
  font-size:16px;
  text-shadow:#000000 1px 1px;
}

.entities .card {
  box-shadow:#000000 0px 0px 10px;
}

.ytimg .col-4 img:hover {
  scale:125%;
}

.tests {
  background:#efefef;
}

.testa {
  background-color:#efefef;
  padding:20px;
}

.testb {
  background-color:#b28c0d;
  padding:20px;
}

.col-3 .card {
  text-align:center;
  margin:auto;
}

.creds {
  background-color:#efefef;
  height: 350px;
}

.crede {
  text-align:center;
}

.crede img {
  max-width:65%;
  margin-top:100px;
}

.mil {
  text-align:center;
}

.mil img {
  max-width:60%;
  margin-top:45px;
}

.shoe {
  border-top:#00528a 2px solid;
  padding:25px 0;
  margin-top:50px;
}

.list-group-item {
  font-family:'Manrope', sans-serif;
}

.stratline {
  margin:50px 0;
}

.stratline h1 {
  font-size:72px;
}

.stratline h4 {
  font-size:32px;
}

.stratline h1, .stratline h4 {
  text-shadow:#b28c0d 1px 1px;
}

.funnelimg img {
  margin:0 auto 50px;
  border:#003a9a 1px solid;
}

.graph {
  margin:50px 0;
}

.gtxt h4 {
  margin-top:25px;
  font-weight:bold;
}

.nav-tabs a {
  color:#777777 !important;
}

.checkboxes .col-3 {
  text-align:center;
}

.roiform .col-4 {
  text-align:center;
}

/* testimonials page */
.widetest {
  margin-bottom:100px;
}

.widetest .col-lg-3 img {
  border:#000000 2px solid;
}

.widetest .col-lg-9 img {
  display:inline-block;
  max-height:40px;
  margin-top:15px;
}

.widetest .col-lg-9 p {
  font-size:20px;
  margin-top:15px;
}

.righty .col-lg-9 {
  text-align:right;
}

.testcards {
  margin-top:50px;
}

.testcards .card-body img {
  display:inline-block;
  max-height:25px;
  margin-bottom:10px;
}

.testcards .card-img-top {
  border-radius:50%;
}

.testcards .card:hover {
  scale:110%;
}

.phonedeck {
  margin-top:50px;
}

.phonedeck .col-lg-2 img {
  max-width:100%;
}

/* Dreambox/Stimqual */
.dreambox {
  margin-top:50px;
}

.dreamcheck {
  text-align:left;
  max-width:25%;
  margin:auto;
}

.stimcheck {
  text-align:left;
  margin:auto;
  max-width:50%;
}

.stimq {
  margin-top:25px;
}

.qualbtn {
  text-align:center;
}

.qualbtn button {
  margin-top:10px;
}

.calc {
  text-align:center;
  margin:0 20%;
  position:absolute;
  top:50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.progress {
  height:50px;
}

#appbtn, #denybtn {
  margin-top:25px;
}

/* Packages/RoI */
.card-deck {
  width:100%;
  margin:25px auto;
}

.pkgcards h1 {
  margin:auto;
}

.pkgcards .card-img-top {
  max-width:100%;
}

.selectpkg {
  text-align:center;
}

/* Order Form */
.promobox {
  text-align:center;
}

.promobox .form-control {
  margin:20px auto;
}

.personalbox {
  margin-top:25px;
}

.personalbox .form-control {
  margin:10px auto;
}

.paybox, .billbox, .bizbox, .tandc {
  text-align:center;
  margin-top:25px;
}

.paybox .form-control {
  margin:10px auto;
}

.billbox .form-control {
  margin:10px auto;
}

.bizbox .form-control {
  margin:10px auto;
}

.tandc {
  margin-bottom:25px;
}

.orderform {
  text-align:center;
}

.orderform .alert {
  text-align:center;
  font-size:18px;
}

.welcome {
  text-align:center;
  margin-top:50px;
  border:#00528a 2px solid;
  border-radius:10px;
}

.welcome h3 {
  margin-top:25px;
}

.welcome img {
  margin:50px auto;
}

.welcome button {
  margin:25px auto;
}

.artbox .col-3, .artbox .col-4 {
  max-width:33%!important;
  max-height:fit-content;
  overflow:hidden;
}

.tallpic {
  height:100%;
  object-fit:none;
  object-position:center;
}

@media only screen and (max-width:900px){
  .bantext {display:none;}
  .externals img {width:100%;}
  .copy {margin-top:0px!important;}
}